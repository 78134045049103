import { AccessTokenKey } from '@/store/auth/auth.store.constants';
import Vue from 'vue';

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  const accessTokenCookies = Vue.$cookies.get(AccessTokenKey);
  return !!accessTokenCookies;
};

/**
 * Get the user data from local storage
 * @returns {Object} The user data
 */
export const getUserData = () => JSON.parse(localStorage.getItem('userData'));

/**
 * Get the new auth route for the given community
 * @param {Object} community - The community object
 * @param {string} query - The query string
 * @returns {string} The new auth route
 */
export const getNewAuthRoute = (community, query = "/") => {
  // if nexos, use the nexos login template directly
  if(process.env.VUE_APP_CUSTOMER === 'nexos'){
    const feURL = process.env.VUE_APP_URL;
    const mainSlug = process.env.VUE_APP_MAIN_SPACE_SLUG;
    return `${feURL}/${mainSlug}/auth/login`;
  }

  // Special case for Intercontinental Alliance
  const isIntercontinentalAllianceCustomer = window.location.hostname.includes('intercontinentalalliance.com');
  // console.log('isIntercontinentalAllianceCustomer', isIntercontinentalAllianceCustomer);
  if(isIntercontinentalAllianceCustomer){
    return 'https://app.intercontinentalalliance.com/intercontinental-alliance/auth/login' + query;
  }

  // if not nexos, use the login template url
  const loginTemplateUrl = process.env.VUE_APP_LOGIN_TEMPLATE_URL;
  // console.log('loginTemplateUrl', loginTemplateUrl);

  // if no login template url or community, return the default login url
  if(!loginTemplateUrl || !community){
    return `${process.env.VUE_APP_MY_NECTIOS_URL}/login`;
  }
  
  // With our parameter normalizer, community should always have the right format
  // But add a simple safety check for backwards compatibility
  let slug = '';
  let subdomain = '';
  
  if (community) {
    if (typeof community === 'string') {
      slug = community;
    } else if (typeof community === 'object' && community !== null) {
      slug = community.slug || '';
      subdomain = community.subdomain || '';
    }
  }
  
  // Log the final values being used
  // console.log(`getNewAuthRoute - using slug: '${slug}', subdomain: '${subdomain}'`);

  // If subdomain is empty, use the default login template url
  // Example VUE_APP_LOGIN_TEMPLATE_URL=https://{collectiveSubdomain}.auth.nectios.com/{collectiveSlug}
  if(!subdomain){
    // console.log('subdomain is empty');
    // Remove the subdomain from the login template url
    return loginTemplateUrl
      .replace("{collectiveSubdomain}", "app")
      .replace("{collectiveSlug}", slug) + query;
  }

  // If subdomain is not empty, use the login template url with the subdomain
  return loginTemplateUrl
    .replace("{collectiveSubdomain}", subdomain)
    .replace("{collectiveSlug}", slug) + query;
}