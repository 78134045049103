<template>
  <div id="app" style="height: 100vh" :class="[skinClasses]">
    
    <!-- VIEW -->
    <router-view />

    <!-- Global Components (chat sidebar) -->
    <GlobalComponents />

    <!-- Cookies Banner -->
    <BannerCookies />

    <!-- GDPR Banner -->
    <GDPRModal />
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig';
import { provideToast } from 'vue-toastification/composition';
import { watch } from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';

import enabledAppsStoreModule from '@/store/enabled-apps/enabledAppsStoreModule';
import { ENABLED_APPS_STORE_MODULE_NAME } from '@/store/enabled-apps/enabled-apps-store-constants';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';

import { useWindowSize, useCssVar } from '@vueuse/core';

import store from '@/store';

import GridStatusMixin from '@core/mixins/apps-layout/GridStatusMixin';

import MetaManager from '@/plugins/meta-manager';
import Vue from 'vue';

Vue.use(MetaManager);

export default {
  name: 'App',

  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout');
    if (skin.value === 'landing') document.body.classList.add('landing-layout');
    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    });

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val);
    });

    return {
      skinClasses,
    };
  },

  components: {
    GlobalComponents: () => import(
      '@core/components/global-components/GlobalComponents.vue'
      /* webpackChunkName: "GlobalComponents" */
    ),
    BannerCookies: () => import('@/@core/components/BannerCookies.vue' /* webpackChunkName: "BannerCookies" */),
    GDPRModal: () => import('@/@core/components/GDPRModal.vue' /* webpackChunkName: "GDPRModal" */),
  },

  mixins: [GridStatusMixin],

  async beforeCreate() {
    // This store module needs to be initialized here, as it's used in the AppBreadcrumb,
    // whichs is outside of the routing structure.
    if (!this.$store.hasModule(ENABLED_APPS_STORE_MODULE_NAME)) {
      this.$store.registerModule(
        ENABLED_APPS_STORE_MODULE_NAME,
        enabledAppsStoreModule,
      );
    }

    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark',
    ];

    for (let i = 0, len = colors.length; i < len; i += 1) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement,
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];
    for (let i = 0, len = breakpoints.length; i < len; i += 1) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement,
        ).value.slice(0, -2),
      );
    }

    // Set RTL (Right to Left)
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
  },

  created() {
    // Set initial meta tags from environment variables
    this.$updateMetaTags({
      title: process.env.VUE_APP_TITLE,
      description: process.env.VUE_APP_DESCRIPTION,
      keywords: process.env.VUE_APP_KEYWORDS
    });
  },

  computed: {
    currentCollectiveSlug() {
      const slug = this.$store.getters.currentCollective?.slug;
      // console.log('App - currentCollectiveSlug computed - raw value:', slug, 'type:', typeof slug);
      
      // Handle object slug
      if (typeof slug === 'object' && slug !== null) {
        // console.warn('App - currentCollectiveSlug is object:', JSON.stringify(slug));
        return slug.slug || slug.id || '';
      }
      
      return slug ? String(slug) : '';
    },
    loggedMemberKey() {
      return this.$store.getters.loggedMember.key;
    },
    roomValue() {
     return this.currentCollectiveSlug;
    },
  },

  watch: {
    roomValue(newRoom, oldRoom) {
      // console.log('Watching the roomValue...');
      // console.log('Room changed from oldRoom:', oldRoom);
      // console.log('To newRoom:', newRoom);

      this.joinSocketRoom(newRoom);
    },
  },

  methods: {
    joinSocketRoom(room) {
      if(!room){
        room = this.currentCollectiveSlug;
      }
      // console.log('Joining room:', room);
      this.$socket.emit('join', room);
    },
    limitString(str, limit) {
      if (str.length > limit) {
        return str.substring(0, limit) + '...';
      }
      return str;
    }
  },

  // SocketIO
  sockets: {
    // Connect to the socket
    connect() {
    //  console.log('socket connected')
    },
    // Disconnect from the socket
    disconnect() {
      // console.log('socket disconnected')
    },
    // Receive message from the socket
    async received(data) {
      // console.log('Message received from the socket:', data);

      const htmlString = data?.message;
      // console.log('htmlString:', htmlString);

      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');
      const innerText = doc.body.textContent.trim();
      // console.log('message received - innerText:', innerText);
      const limitedMessage = this.limitString(innerText, 100);

      const currentPath = this.$route.fullPath.startsWith("/") ? this.$route.fullPath.slice(1) : this.$route.fullPath;
      // console.log('currentPath:', currentPath);
      // console.log('this.currentCollectiveSlug:', this.currentCollectiveSlug);
      const inHomeView = true;  //currentPath !== this.currentCollectiveSlug;
      const inGroupChat = data?.room === this.currentCollectiveSlug;

      if (inHomeView) {
        // console.log('Message received in a different room than me...');
        if (this.loggedMemberKey !== data?.memberData?.key) {
          // console.log('Message received from a different member than me...');
          // Show toast notification
          // const senderFullName = data?.memberData ? data.memberData.name + ' ' + data.memberData.surname : null;
          const title = "New " + (inGroupChat ? 'Group' : 'Private') + ' Chat Message';
          const from = data?.memberData;
          
          // Ensure communitySlug is always a string for navigation
          let safeSlug = this.currentCollectiveSlug;
          if (typeof safeSlug === 'object' && safeSlug !== null) {
            // console.warn('App.vue socket - communitySlug is an object in notification handler:', JSON.stringify(safeSlug));
            safeSlug = safeSlug.slug || safeSlug.id || '';
          }
          safeSlug = String(safeSlug || '');
          // console.log('App socket notification - using safeSlug for navigation:', safeSlug);
          
          const buttonLink = '/' + safeSlug + '/' + (inGroupChat ? 'chat' : '?chat-user=' + data?.memberData?.username);
          
          // const avatarUrl = data?.memberData?.avatarURL;
          const messageFormate = {
            title: title,
            user: from,
            text: limitedMessage,
            buttonLink: buttonLink,
            icon: 'InfoIcon',
            variant: 'info',
            hideClose: true
          }
          this.$toast({
            component: ToastificationContentVue,
            props: messageFormate,
          });
        } else {
          // console.log('Message received from the same member as me...');
        }
      } else {
        // console.log('Message received in the same room as the current one...');
      }
    },
  },

  mounted() {
    // Set customer-specific attributes on root element
    if (process.env.VUE_APP_CUSTOMER === 'nexos') {
      // console.log('Setting Nexos theme...');
      
      const rootElement = document.documentElement;
      rootElement.setAttribute('data-customer', 'nexos');
      
      // Add BDS class to enable Bancolombia Design System
      rootElement.classList.add('bds-enabled');
      
      // Set BDS version as data attribute
      rootElement.setAttribute('data-bds-version', '7.14.4');
    }
  },
};
</script>
